<template>
  <div class="overflow-hidden lg:flex lg:h-screen">
    <div class="bg-mint lg:flex-1">
      <DefaultGrid class="h-full">
        <div class="relative col-span-full h-full text-center">
          <div
            class="flex justify-center pt-10 lg:absolute lg:inset-x-0 lg:top-0"
          >
            <NuxtLink to="/">
              <SvgLogo class="h-[85px] text-mint md:h-[105px]" />
            </NuxtLink>
          </div>
          <div
            class="py-16 md:pb-28 lg:flex lg:h-full lg:items-center lg:justify-center lg:p-0"
          >
            <LockupPledge
              class="text-[78px]/[1em] md:text-[116px]/[1em] lg:text-[10vmax]/[1em]"
            />
          </div>
        </div>
      </DefaultGrid>
    </div>
    <div class="overflow-y-auto lg:flex-1">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
useLayout()
</script>
